<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-14 10:28:24
 * @LastEditTime: 2024-06-17 16:10:05
-->
<template>
  <div class="main">
    <div class="box top-box" style="overflow: hidden">
      <div class="user">
        <div class="info">
          <div class="avator">
            <img :src="user.avatarImage" />
          </div>
          <div class="nameAndPhone">
            <div class="name">{{ user.nickName }}</div>
            <div class="phone">{{ user.phone }}</div>
          </div>
          <div class="wallet">
            <button @click="goWallet">
              <img
                :src="`${publickPath}static/img/icons/icon-wallet.png`"
              />钱包
            </button>
          </div>
        </div>
      </div>
      <div class="nav-box">
        <div
          class="nav-item"
          v-for="(nav, i) in navs"
          :key="i"
          @click="navClick(nav)"
        >
          <div class="icon">
            <img :src="`${publickPath}static/img/icons/` + nav.icon" />
          </div>
          <div class="name">{{ nav.name }}</div>
          <div v-if="nav.badge" class="badge">
            {{ nav.badge }}
          </div>
          <div
            class="hyddxcx"
            v-if="isSm && nav.isJumpMini && nav.miniPath"
            @click="toXcx()"
          >
            <wx-open-launch-weapp
              style="baoliao"
              :id="miniId"
              :username="miniId"
              :path="nav.miniPath"
            >
              <script type="text/wxtag-template">
                <style>
                    .text{
                      height: 93px;
                      width: 87px;
                      display: block;
                      color: #888;
                      opacity: 0;
                    }
                  </style>
                  <div class="text">好用点点小程序</div>
              </script>
            </wx-open-launch-weapp>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="nav-list">
        <template v-for="(nav, i) in visitNavs">
          <div
            class="nav-item"
            :key="i"
            v-if="!nav.noShow"
            @click="navClick(nav)"
          >
            <div class="icon">
              <img :src="`${publickPath}static/img/icons/` + nav.icon" />
            </div>
            <div class="name">{{ nav.name }}</div>
            <div class="tip">
              <div v-if="nav.badge" class="badge">
                {{ nav.badge }}
              </div>
              <div class="count" v-if="nav.count">{{ nav.count }}条</div>
              <van-icon name="arrow" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="box">
      <div class="nav-list">
        <template v-for="(nav, i) in myNavs">
          <div
            class="nav-item"
            :key="i"
            v-if="!nav.noShow"
            @click="navClick(nav)"
          >
            <wx-open-launch-weapp
              v-if="nav.name == '我的车辆'"
              style="baoliao"
              class="item-box"
              :id="miniId"
              :username="miniId"
              path="/pages/authorization?path=/pagesA/mycar/mycar"
            >
              <script type="text/wxtag-template">
                <style>
                  .text {
                    width: 23.115rem;
                    height: 3.8rem;
                    background-color:red;
                  }
                </style>
                <div>
                  <div  class="text"></div>
                </div>
              </script>
            </wx-open-launch-weapp>
            <div class="icon">
              <img :src="`${publickPath}static/img/icons/` + nav.icon" />
            </div>
            <div class="name">{{ nav.name }}</div>
            <div class="tip">
              <div v-if="nav.badge" class="badge">
                {{ nav.badge }}
              </div>
              <div class="count" v-if="nav.count">
                {{ nav.count }}{{ nav.name === '我的商家' ? '家' : '条' }}
              </div>
              <van-icon name="arrow" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="box">
      <div class="nav-list">
        <div
          class="nav-item"
          v-for="(nav, i) in otherNavs"
          :key="i"
          @click="navClick(nav)"
        >
          <div class="icon">
            <img :src="`${publickPath}static/img/icons/` + nav.icon" />
          </div>
          <div class="name">{{ nav.name }}</div>
          <div class="tip">
            <div class="count" v-if="nav.count">{{ nav.count }}条</div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <!-- <toast-modal ref="tostModal" @cancelZ="cancelZ"></toast-modal> -->
  </div>
</template>
<script>
import { userMyCount } from "@utils/aRequest";
import { mapState, mapGetters } from "vuex";
import toastModal from "@/components/toastModal";
import { Form } from "vant";
export default {
  data() {
    return {
      state: 0,
      isSm: false,
      publickPath: process.env.BASE_URL,
      user: {},
      // 好用点点小程序id
      miniId: "gh_67be8caedeb9",
      isJumpMini: true, // 如果不是紫晶商务城小区则跳转小程序
      zjZoneId: "2c9280827477949e01747c32106c0000",
      navs: [
        {
          name: "家/单位",
          icon: "icon-my-house.png",
          isJumpCheck: true,
          needAudit: true,
          isJumpMini: true,
          jump: "/pages/home/mine/my-detail",
          miniPath: "/pages/authorization?path=my/address/index"
        },
        {
          name: "访问记录",
          icon: "icon-record-access.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "/pages/home/mine/visit-history",
          miniPath: "/pages/authorization?path=my/visitorJL/index"
        },
        {
          name: "访客审核",
          icon: "icon-record-audit.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "/pages/home/mine/check-record",
          miniPath: "/pages/authorization?path=my/visitorSh/index"
        },
        {
          name: "购买记录",
          icon: "icon-record-buy.png",
          path: "/goods/BuyRecord",
          count: 0,
          badge: 0
        }
      ],
      visitNavs: [
        {
          name: "人口登记",
          icon: "icon-population-registrationss.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "pages/home/domicile/index"
        },
        {
          name: "访客邀请",
          icon: "icon-visit-invited.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "pages/house-owner/visitor-invited/visitor-invited-list",
          miniPath: "/pages/authorization?path=my/visitorYq/index"
        },
        {
          name: "委托接送",
          icon: "icon-visit-pickup.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "pages/home/grade/entrust",
          miniPath: "/pages/authorization?path=my/child/index",
          noShow: true
        },
        {
          name: "我的班级",
          icon: "icon-visit-class.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "pages/home/grade/index",
          miniPath: "/pages/authorization?path=my/class/index",
          noShow: true
        }
      ],
      myNavs: [
        {
          name: "我的车辆",
          code: "car-list-per",
          icon: "my-car.png",
          path: "",
          count: 0,
          badge: 0,
          needAudit: true
        },
        {
          name: "查车牌管理",
          code: "car-list-per",
          icon: "icon-car.png",
          path: "",
          count: 0,
          badge: 0,
          jump: "/che/pages/car-list-per.html", // 余小钦可看
          noShow: true
        },
        {
          name: "查车牌",
          code: "car-list",
          icon: "icon-car.png",
          path: "",
          count: 0,
          badge: 0,
          jump: "/che/pages/car-list.html", // 余小钦小区用户查看
          noShow: true
        },
        {
          name: "优惠商铺管理",
          code: "shop-list-per",
          icon: "icon-shop.png",
          path: "",
          count: 0,
          badge: 0,
          jump: "/che/pages/shop-list-per.html", // 余小钦可看
          noShow: true
        },
        {
          name: "优惠商铺",
          code: "shop-list",
          icon: "icon-shop.png",
          path: "",
          count: 0,
          badge: 0,
          jump: "/che/pages/shop-list.html", // 余小钦小区用户查看
          noShow: true
        },
        {
          name: "我的商家",
          icon: "icon-my-tenants.png",
          path1: "/my/shopsCheckIn",
          path2: "/my/businessList", //存在count进入当前路由
          needAudit: true,
          count: 0,
          badge: 0
        },
        {
          name: "好货审核",
          icon: "icon-my-good-cargor.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "/pages/home/market-check/market-check-list"
        },
        {
          name: "我的拼团",
          icon: "icon-my-order.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "/pages/home/group-booking/my-group-list"
        },
        {
          name: "拼团审核",
          icon: "icon-my-spell-group.png",
          path: "",
          count: 0,
          badge: 0,
          isJumpCheck: true,
          jump: "/pages/home/group-check/group-check-list"
        }
      ],
      otherNavs: [
        {
          name: "更多服务",
          icon: "icon-other-service.png",
          path: "/my/more",
          count: 0
        }
      ],
      carSfzh: [
        "330325197804180417",
        "342901198912173812",
        "360481199311052223",
        "33252219961007968x",
        "331021199701272317",
        "142301199808090296"
      ],
      carZones: [
        "2c92808274680353017471d9afa50076",
        "2c9280827477949e01748244c17e0019",
        "2c92808474139b650174142be4f80000",
        "402881bf72f551630172f553060d010m",
        "2c92808474139b650174142f9fa70001"
      ],
      shopZones: [
        "2c92808274680353017471d9afa50076",
        "2c9280827477949e01748244c17e0019",
        "2c92808474139b650174142be4f80000",
        "402881bf72f551630172f553060d010m",
        "2c92808474139b650174142f9fa70001",
        "2c918088778c906d0177b7bc30ed0000"
      ]
    };
  },
  components: {
    toastModal
  },
  watch: {},
  created() {
    this.isSm = this.checkUserState();
    let roleData = JSON.parse(JSON.stringify(this.returnRoleData));
    this.user = roleData.userPO;
    if (this.carSfzh.indexOf(this.user.certificateNum) > -1) {
      this.myNavs.forEach(item => {
        if (item.code === "car-list-per" || item.code === "shop-list-per") {
          // 余小钦查车牌管理和优惠商铺管理
          item.noShow = false;
        }
      });
    }
    // 查车牌和优惠商铺
    this.getInitMenu();
    this.isJumpMini = !(localStorage.getItem("zoneId") === this.zjZoneId);
    console.log("isJumpMini", localStorage.getItem("zoneId"), this.isJumpMini);
    this.$bus.on("changeZoneId", zoneId => {
      this.getInitMenu();
      this.isJumpMini = !(zoneId === this.zjZoneId);
      console.log("changeZoneId", zoneId, this.isJumpMini);
    });

    let phone = roleData.userPO.phone;
    if (phone) {
      this.user.phone = phone.substr(0, 3) + "****" + phone.substr(7, 4);
    }
    if (roleData.danwei.length !== 0) {
      let danwei = roleData.danwei[0];
      if (
        danwei.userZoneRelationPO.zoneType === 2 &&
        (danwei.userZoneRelationPO.userType === 9 ||
          danwei.userZoneRelationPO.userType === 8)
      ) {
        this.visitNavs.forEach(item => {
          if (item.name === "我的班级") {
            item.noShow = false;
          }
        });
      }
    }

    let parent = roleData.parentPOS;
    if (parent.length > 0) {
      this.visitNavs.forEach(item => {
        if (item.name === "委托接送") {
          item.noShow = false;
        }
      });
    }
    //初始化数据
    this.getInitialization();
    console.log(this.$route, 'eeee')
    if(this.$route.query.url) {
      this.$router.push(this.$route.query.url)
    }
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["returnRoleData"])
  },
  methods: {
    getInitialization() {
      var self = this;
      // //系统公告
      userMyCount().then(data => {
        if (data.code == "SUCCESS") {
          console.log(data);
          // if (data.data.visitorRecordCount) {
          //   self.recordNavs[0].badge = data.data.visitorRecordCount;
          // }
          // 好货审核
          if (data.data.topicCount) {
            self.myNavs[5].badge = data.data.topicCount;
          }
          // 拼团审核
          if (data.data.topicCount) {
            self.myNavs[7].badge = data.data.topicCount;
          }
          // 我的商家
          if (data.data.businessCount) {
            self.myNavs[4].count = data.data.businessCount;
          }
        }
      });
    },
    // 查车牌和优惠商铺菜单加载
    getInitMenu() {
      if (this.carZones.indexOf(localStorage.getItem("zoneId")) > -1) {
        this.myNavs.forEach(item => {
          if (item.code === "car-list") {
            // 余小钦管理小区的查车牌
            item.noShow = false;
          }
        });
      } else {
        this.myNavs.forEach(item => {
          if (item.code === "car-list") {
            // 余小钦管理小区的查车牌
            item.noShow = true;
          }
        });
      }
      if (this.shopZones.indexOf(localStorage.getItem("zoneId")) > -1) {
        this.myNavs.forEach(item => {
          if (item.code === "shop-list") {
            // 余小钦管理小区的优惠商铺
            item.noShow = false;
          }
        });
      } else {
        this.myNavs.forEach(item => {
          if (item.code === "shop-list") {
            // 余小钦管理小区的优惠商铺
            item.noShow = true;
          }
        });
      }
    },
    goWallet() {
      // console.log("钱包");
      this.$globalFun.setUrlHeader("/pages/home/wallet/index");
    },
    checkUserState() {
      let state = 0; // 默认没有实名验证过
      let roleData = JSON.parse(localStorage.getItem("roleData"));
      if (roleData) {
        if (roleData.userPO.phone) {
          if (roleData.userPO.rlsbStatus == 1) {
            if (roleData.xiaoqu.length > 0) {
              var xiaoquArr = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.addrFlag === 1 &&
                  item.userZoneRelationPO.status === 2
              ); // 已通过并设置为默认房屋的数据
              var xiaoquCheck = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 2 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 审核通过并未注销的房屋
              var xiaoqu =
                xiaoquArr.length !== 0
                  ? xiaoquArr[0]
                  : xiaoquCheck.length !== 0
                    ? xiaoquCheck[0]
                    : null;
              var noCheck = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 1 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 有房屋但未审核
              var noCompleteZone = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 0 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 有房屋但未完善
              var noPassZone = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 3 ||
                  item.userZoneRelationPO.cancel === 1
              ); // 有房但未通过或已注销
              if (xiaoqu) {
                state = 4; // 有住房且通过审核
              } else if (noCheck.length > 0) {
                state = 5; // 有住房但是未审核
              } else if (noCompleteZone.length > 0) {
                // 有房子但未完善
                state = 3;
              } else if (noPassZone.length > 0) {
                // 有房但未通过或已注销
                state = 3;
              }
            } else {
              state = 3; // 实名过没房子
            }
            if (state <= 3) {
              if (roleData.danwei.length > 0) {
                var danweiArr = roleData.danwei.filter(
                  item =>
                    item.userZoneRelationPO.addrFlag === 1 &&
                    item.userZoneRelationPO.status === 2
                ); // 已通过并设置为默认单位的数据
                var danweiCheck = roleData.danwei.filter(
                  item =>
                    item.userZoneRelationPO.status === 2 &&
                    item.userZoneRelationPO.cancel === 0
                ); // 审核通过并未注销的单位
                var danwei =
                  danweiArr.length !== 0
                    ? danweiArr[0]
                    : danweiCheck.length !== 0
                      ? danweiCheck[0]
                      : null;
                var noCheck = roleData.danwei.filter(
                  item =>
                    item.userZoneRelationPO.status === 1 &&
                    item.userZoneRelationPO.cancel === 0
                ); // 有单位但未审核
                var noCompleteZone = roleData.danwei.filter(
                  item =>
                    item.userZoneRelationPO.status === 0 &&
                    item.userZoneRelationPO.cancel === 0
                );
                // 有单位但未完善
                var noPassZone = roleData.danwei.filter(
                  item =>
                    item.userZoneRelationPO.status === 3 ||
                    item.userZoneRelationPO.cancel === 1
                ); // 有单位但未通过或已注销
                if (danwei) {
                  state = 4; // 有单位且通过审核
                } else if (noCheck.length > 0) {
                  state = 5; // 有单位但是未审核
                } else if (noCompleteZone.length > 0) {
                  // 有单位但未完善
                  state = 3;
                } else if (noPassZone.length > 0) {
                  // 有单位但未通过或已注销
                  state = 3;
                }
              } else {
                state = 3; // 实名过没单位
              }
            }
          } else {
            state = 2; // 未实名认证过
          }
        } else {
          state = 1; // 手机未验证过
        }
      }
      this.state = state;
      console.log(this.state, "this.state");
      if (state >= 4) {
        return true;
      } else {
        return false;
      }
    },
    navClick(nav) {
      //判断是否需要实名验证
      if (nav.needAudit && !this.$globalFun.checkUserState()) {
      } else {
        if (nav.name == "访客邀请") {
          if (this.state > 3) {
            this.$router.push(nav.jump);
          } else if (this.state == 3) {
            this.$refs.tostModal.open(
              "通行码便于扫码通行，一次注册，永久便捷，请按登记流程规范填写，谢谢！",
              3
            );
            return false;
          } else {
            this.$refs.tostModal.open();
            return false;
          }
        }
        if (nav.name == "我的商家") {
          if (nav.count) {
            this.$router.push(nav.path2);
          } else {
            this.$router.push(nav.path1);
          }
          return;
        }
        if (
          nav.name == "查车牌" ||
          nav.name == "查车牌管理" ||
          nav.name == "优惠商铺" ||
          nav.name == "优惠商铺管理"
        ) {
          this.$globalFun.goUrl(nav.jump);
        }
        if (nav.isJumpCheck) {
          this.$globalFun.setUrlHeader(nav.jump);
        } else {
          if (nav.path) {
            this.$router.push(nav.path); //本地跳转
          }
        }
      }
      // }
    },
    toXcx() {
      // alert('to小程序')
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding-bottom: 3rem;
  background-color: #f6f6f6;
}
.user {
  padding: 1.6rem /* 30/18.75 */ 0.8rem /* 15/18.75 */ 0.32rem /* 6/18.75 */;
  // padding: 6.4rem /* 120/18.75 */ 0.8rem /* 15/18.75 */ 0;
  position: relative;
  // background-image: url(/static/img/newYear/my-bg.png);
  // background-repeat: no-repeat;
  // background-size: 100%;
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: -166%;
    left: 50%;
    transform: translate(-50%);
    width: 30rem;
    height: 20rem;
    border-radius: 50%;
    background: linear-gradient(-90deg, #63b2ff, #2b71fd);
  }
  > .info {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 12px 0px rgba(4, 0, 0, 0.08);
    border-radius: 0.533333rem /* 10/18.75 */;
    padding: 1.173333rem /* 22/18.75 */ 0.64rem /* 12/18.75 */;
    display: flex;
    .avator {
      height: 3.413333rem /* 64/18.75 */;
      width: 3.413333rem /* 64/18.75 */;
      img {
        width: 3.413333rem /* 64/18.75 */;
        height: 3.413333rem /* 64/18.75 */;
        border-radius: 50%;
      }
    }
    .nameAndPhone {
      width: calc(100% - 3.413333rem /* 64/18.75 */ - 4rem /* 75/18.75 */);
      line-height: 1.6rem /* 30/18.75 */;
      padding: 0 1.6rem /* 30/18.75 */ 0 0.64rem /* 12/18.75 */;
      text-align: left;
      .name {
        font-size: 1.066667rem /* 20/18.75 */;
        font-family: PingFang SC;
        font-weight: 500;
        color: @fcb;
        .ellipsis;
        margin-top: 0.266667rem /* 5/18.75 */;
      }
      .phone {
        font-size: 0.853333rem /* 16/18.75 */;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
      }
    }
    .wallet {
      width: 4.4rem /* 75/18.75 */;
      button {
        outline: none;
        border: none;
        width: 4.4rem /* 75/18.75 */;
        height: 1.493333rem /* 28/18.75 */;
        line-height: 1.493333rem /* 28/18.75 */;
        background: linear-gradient(180deg, #63b2ff, #2b71fd);
        // background: linear-gradient(180deg, #F28648, #EE3E47);
        border-radius: 0.746667rem /* 14/18.75 */;
        text-align: center;
        font-size: 0.8rem /* 15/18.75 */;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        // color: #FAE8AE;
        margin-top: 0.373333rem /* 7/18.75 */;
        .df;
        justify-content: center;
        img {
          width: 0.853333rem /* 16/18.75 */;
          height: 0.853333rem; /* 16/18.75 */
          margin-right: 0.266667rem /* 5/18.75 */;
        }
      }
    }
  }
}
.nav-box {
  display: flex;
  .nav-item {
    width: 25%;
    padding: 0.96rem /* 18/18.75 */ 0 0.8rem /* 15/18.75 */;
    position: relative;
    .icon {
      // position: relative;
      display: flex;
      justify-content: center;
      img {
        height: 1.6rem /* 30/18.75 old*/;
        // height: 2.56rem /* 48/18.75 new*/;
      }
    }
    .name {
      font-size: 0.8rem /* 15/18.75 */;
      line-height: 0.8rem /* 15/18.75 */;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      text-align: center;
      margin-top: 0.533333rem /* 10/18.75 old*/;
      // margin-top: .266667rem /* 5/18.75 new*/;
    }
    .badge {
      position: absolute;
      top: 0.64rem /* 12/18.75 */;
      right: 1.333333rem /* 25/18.75 */;
      padding: 0 0.16rem /* 3/18.75 */;
      height: 0.746667rem /* 14/18.75 */;
      line-height: 0.746667rem /* 14/18.75 */;
      background: linear-gradient(90deg, #fd5f75, #f44646);
      border-radius: 0.373333rem /* 7/18.75 */;
      font-size: 0.586667rem /* 11/18.75 */;
      font-family: SourceHanSansSC;
      font-weight: 400;
      color: #ffffff;
      // margin-right: 0.32rem /* 6/18.75 */;
      text-align: center;
    }
  }
}
.nav-list {
  padding: 0 1.066667rem /* 20/18.75 */;
  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.96rem /* 18/18.75 */ 0;
    border-bottom: 0.053333rem /* 1/18.75 */ solid #f6f6fa;
    .item-box {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      opacity: 0;
    }
    .icon {
      margin-right: 0.8rem /* 15/18.75 */;
      width: 1.066667rem /* 20/18.75 */;
      height: 1.066667rem /* 20/18.75 */;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .name {
      flex: 1;
      font-size: 0.8rem /* 15/18.75 */;
      font-family: PingFang SC;
      font-weight: 400;
      color: @fcb;
      text-align: left;
    }
    .tip {
      display: flex;
      align-items: center;
      .count {
        font-size: 0.693333rem /* 13/18.75 */;
        font-family: PingFang SC;
        font-weight: 400;
        color: @fcg;
      }
      .badge {
        padding: 0 0.266667rem /* 5/18.75 */;
        height: 0.693333rem /* 13/18.75 */;
        line-height: 0.693333rem /* 13/18.75 */;
        background: linear-gradient(90deg, #fd5f75, #f44646);
        border-radius: 0.373333rem /* 7/18.75 */;
        font-size: 0.586667rem /* 11/18.75 */;
        font-family: SourceHanSansSC;
        font-weight: 400;
        color: #ffffff;
        // margin-right: 0.32rem /* 6/18.75 */;
        text-align: center;
      }
      i {
        color: #b4b4b4;
        font-size: 0.64rem /* 12/18.75 */;
        margin-left: 0.266667rem /* 5/18.75 */;
      }
    }
  }
}

.hyddxcx {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
</style>
